import MainLayout from '../layout/MainLayout';
import React, { useState } from 'react';
import Section from '../Section';
import { Link, navigate } from 'gatsby';
import { Alert, Button, List, Typography } from 'antd';
import tailwindConfig from '../../../tailwind.config';
import { ICartItem } from '../cart/CartItem';
import { formatPrice } from '../../utils/format-price';
import { useCart } from '../../hooks/use-cart';
import Breadcrumb from '../Breadcrumb';
import { CartProductDetail } from '../cart/CartProductDetail';

const resolveConfig = require('tailwindcss/resolveConfig');
const config = resolveConfig(tailwindConfig);

const { Text, Title } = Typography;

const CartItem = ({ item }: { item: ICartItem }) => {
  return (
    <div className="p-5 md:p-6 border border-light-divider last:border-0 flex fl">
      <CartProductDetail item={item}>
        <Text className="md:hidden !text-light-title !text-sm">
          {item.quantity}x
        </Text>
        <Text className="md:hidden !text-light-title !text-sm">
          ฿ {item.unit_price ? formatPrice(item.total) : '-'}
        </Text>
      </CartProductDetail>
      <div className="hidden md:flex w-[110px] justify-end">
        <Text className="!text-light-title !text-sm">
          ฿ {item.unit_price ? formatPrice(item.unit_price) : '-'}
        </Text>
      </div>
      <div className="hidden md:flex w-[80px] justify-end">
        <Text className="!text-light-title !text-sm">{item.quantity}x</Text>
      </div>
      <div className="hidden md:flex w-[150px] justify-end">
        <Text className="!text-light-title !text-sm">
          ฿ {item.unit_price ? formatPrice(item.total) : '-'} THB
        </Text>
      </div>
    </div>
  );
};

const CheckoutPage = () => {
  const {
    cart,
    actions: { setVisible, completeCart },
  } = useCart();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const onCheckout = async () => {
    setLoading(true);
    try {
      const order = await completeCart();
      navigate(`/checkout-success?orderId=${order?.display_id}`);
    } catch (_) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainLayout logoOnly>
      <Breadcrumb
        items={[
          {
            title: 'Checkout',
            link: '/checkout',
          },
        ]}
        title="ตะกร้าสินค้า"
      />
      <Section className="flex-1 flex flex-col lg:flex-row py-6">
        <div className="flex-1 overflow-hidden border bg-neutral-1 border-light-divider shadow-small rounded-[6px] h-fit">
          <div className="flex w-full justify-between px-5 md:px-6 py-3 border-b border-light-divider">
            <Text className="!text-base !font-semibold !text-light-title">
              สินค้าในตะกร้า
            </Text>
            <Link
              className="!text-base !font-semibold"
              to="/cart"
              onClick={(e) => {
                if (window.screen.width >= 768) {
                  e.stopPropagation();
                  e.preventDefault();
                  setVisible(true);
                }
              }}
            >
              แก้ไข
            </Link>
          </div>
          <div className="bg-light-lighter">
            <List
              dataSource={cart.items}
              renderItem={(item) => <CartItem item={item} />}
            />
          </div>
        </div>
        <div className="ml-0 mt-6 lg:mt-0 lg:ml-6 w-full lg:max-w-[378px] flex-1 overflow-hidden border bg-neutral-1 border-light-divider shadow-small rounded-[6px] h-fit">
          <div className="w-full justify-between px-5 md:px-6 py-3 border-b border-light-divider">
            <Text className="!text-base !font-semibold !text-light-title">
              สรุปคำสั่งซื้อ
            </Text>
          </div>
          <div className="bg-light-lighter p-5 md:p-6 flex flex-col">
            <Text className="!font-medium !text-light-secondary">ราคารวม</Text>
            <Title
              level={3}
              className="!mt-2 !mb-0 !font-medium !text-light-title"
            >
              ฿ {cart.total ? formatPrice(cart.total) : '-'} THB
            </Title>
            <Alert
              className="!items-baseline !text-light-title !text-sm !mt-6"
              message="ราคารวมที่แสดงนี้ถูกคำนวณจากสินค้าที่มีการระบุราคาเอาไว้เท่านั้น"
              type="info"
              showIcon
            />
            <Button
              loading={loading}
              type="primary"
              onClick={onCheckout}
              block
              className="mt-6"
              size="large"
            >
              ยืนยันคำสั่งซื้อ
            </Button>
            {error && (
              <Alert
                className="!items-baseline !text-light-title !mt-4"
                message="ขออภัย พบปัญหาในการสั่งซื้อสินค้า กรุณาตรวจสอบข้อมูลของคุณและลองใหม่อีกครั้ง"
                type="error"
                showIcon
              />
            )}
          </div>
        </div>
      </Section>
    </MainLayout>
  );
};

export default CheckoutPage;
