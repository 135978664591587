import React from 'react';
import CheckoutPage from '../components/checkout/CheckoutPage';
import SearchEngineOptimization from '../components/utility/seo';

const Checkout = () => {
  return (
    <div>
      <SearchEngineOptimization title="Checkout" />
      <CheckoutPage />
    </div>
  );
};

export default Checkout;
